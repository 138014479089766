import React, { useState } from 'react';
import { ColorScheme, ColorSchemeProvider, Container, MantineProvider } from '@mantine/core';
import './App.css';
import { MainPage } from './pages/MainPage';
import { Notifications } from '@mantine/notifications';

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(window.localStorage.getItem('colorScheme') as ColorScheme ?? 'dark');
  const toggleColorScheme = (value?: ColorScheme) => {
    const newColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark');
    setColorScheme(newColorScheme);
    window.localStorage.setItem('colorScheme', newColorScheme);
  }
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <Notifications />
        <Container p={0} size="sm">
          <MainPage />
        </Container>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
