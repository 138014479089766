import { Button, Select, TextInput, Flex, Textarea } from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { addMinutes, format, isBefore, isValid, parseISO } from "date-fns";
import { addDoc } from "firebase/firestore";
import { useState } from "react";
import { DBUser } from "../../types/DBUser";
import { db } from "../../utils/db";
import { PhoneInput } from "../../utils/PhoneInput";
import { convertToDate } from "../../utils/timeUtils";
import { EventModal } from "./EventModal";

type Props = {
  goBack: () => void;
  user: DBUser;
  someoneElse?: boolean;
};

type Requestor = 'myself' | 'someone';

const timeError = 'Requested pickup time can\'t be in the past. Did you mean to schedule pickup for tomorrow?';

export const ScheduleRide = ({ goBack, user, someoneElse }: Props) => {
  const [loading, setLoading] = useState(false);
  const [requestingFor, setRequestingFor] = useState<Requestor>(someoneElse ? 'someone' : 'myself');
  const [riderName, setRiderName] = useState('');
  const [riderPhoneNumber, setRiderPhoneNumber] = useState('');
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [timeValue, setTimeValue] = useState<string>(format(addMinutes(new Date(), 30), 'HH:mm'));
  const [pickupDay, setPickupDay] = useState<Date>(new Date());
  const [numRiders, setNumRiders] = useState('1');
  const [note, setNotes] = useState('');

  const [showEventModal, setShowEventModal] = useState(false);

  const requestedDateTime = convertToDate(timeValue, pickupDay);

  const createRideRequest = async () => {
    setLoading(true);
    setShowEventModal(false);
    await addDoc(db.rideRequest, {
      requestedByUserId: user.id,
      requestedForName: riderName || user.name,
      riderPhoneNumber: riderPhoneNumber || null,
      pickupLocation,
      dropoffLocation,
      note,
      pickupTimeRequested: convertToDate(timeValue, pickupDay),
      numRiders: parseInt(numRiders, 10),
      /* Will be populated by Driver */
      pickupTimeRevised: null,
      driverId: null,
      rideCompleted: false,
    })
      .then(goBack)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Flex direction="column" p="md" gap="md">
      <EventModal
        open={showEventModal}
        onClose={() => setShowEventModal(false)}
        onConfirm={createRideRequest}
      />
      <Select
        label="Requesting for"
        value={requestingFor}
        onChange={(u) => setRequestingFor(u as Requestor ?? 'myself')}
        data={[
          {
            label: 'Myself',
            value: 'myself' as Requestor,
          },
          {
            label: 'Someone Else',
            value: 'someone' as Requestor,
          },
        ]}
        disabled={loading}
      />
      {
        requestingFor === 'someone' && (
          <>
            <TextInput
              withAsterisk
              autoFocus
              label="Requesting ride for"
              placeholder="Rider name"
              value={riderName}
              onChange={(e) => setRiderName(e.target.value)}
              disabled={loading}
            />
            <PhoneInput
              label="Rider phone number"
              forceDescription
              description={'If left blank, drivers will contact you directly for updates about this ride.'}
              onChangeValue={setRiderPhoneNumber}
              disabled={loading}
            />
          </>
        )
      }
      <TextInput
        withAsterisk
        label="Pickup location"
        autoFocus={!someoneElse}
        value={pickupLocation}
        onChange={(e) => setPickupLocation(e.target.value)}
        disabled={loading}
      />
      <TextInput
        withAsterisk
        label="Dropoff location"
        value={dropoffLocation}
        onChange={(e) => setDropoffLocation(e.target.value)}
        disabled={loading}
      />
      <Flex gap="xs" align="flex-end">
        <TimeInput
          withAsterisk
          onChange={(e) => setTimeValue(e.target.value)}
          value={timeValue}
          label="Requested pickup"
          withSeconds={false}
          error={isValid(requestedDateTime) && isBefore(requestedDateTime, new Date()) ? timeError : null}
          disabled={loading}
          />
        <DateInput
          withAsterisk
          value={pickupDay}
          onChange={(d) => setPickupDay(d ?? pickupDay)}

          minDate={new Date()}
          // TODO: When does the event end?
          // maxDate={parseISO('2023-08-07')}
          weekendDays={[]}
        />
      </Flex>
      <Select
        withAsterisk
        label="Number of passengers"
        description="This is including yourself."
        value={numRiders}
        onChange={(n) => setNumRiders(n ?? numRiders)}
        data={[1,2,3].map((n) => ({
          label: `${n} passenger${n > 1 ? 's' : ''}`,
          value: `${n}`,
        }))}
        disabled={loading}
      />
      <Textarea
        label="Notes for the Driver"
        value={note}
        onChange={(e) => setNotes(e.target.value)}
        disabled={loading}
      />
      <Button
        onClick={() => setShowEventModal(true)}
        radius="md"
        disabled={
          loading ||
          !isValid(requestedDateTime) ||
          !pickupLocation ||
          !dropoffLocation
        }
        variant="gradient"
      >
        Request Ride
      </Button>
      <Button
        onClick={goBack}
        radius="md"
        variant="outline"
        disabled={loading}
      >
        Cancel
      </Button>
    </Flex>
  )
};