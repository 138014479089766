import { Button, Flex, Modal, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../utils/db";
import { PhoneInput } from "../../utils/PhoneInput";

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const AddDriver = ({ opened, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    const q = query(
      db.users,
      where("phoneNumber", "==", phoneNumber)
    );
    const snap = await getDocs(q);
    if (snap.empty) {
      setError('No user found this this phone number.')
    }
    const user = snap.docs[0].data();
    const ref = doc(db.users, user.id);
    await updateDoc(ref, { role: 'driver' })
      .then(() => {
        notifications.show({
          title: `${user.name} is now a Driver!`,
          message: 'Happy driving!',
          autoClose: true,
        });
        onClose();
      })
      .catch((e) => {
        console.error(e);
        setError('Something went wrong.')
      });
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      centered
    >
      <Flex
        direction="column"
        gap="md"
      >
        <Text>
          Please enter the phone number
          of the user you want to add as a Driver.
        </Text>
        <PhoneInput
          onChangeValue={(v) => {
            setPhoneNumber(v);
            setError('');
          }}
          description="The user must have already registered with this number."
          error={error}
          disabled={loading}
        />
        <Button
          onClick={onSubmit}
          variant="gradient"
          disabled={
            loading || phoneNumber.length < 11
          }
        >
          Submit
        </Button>
        <Button
          variant="subtle"
          onClick={onClose}
          disabled={loading}
        >
          Go Back
        </Button>
      </Flex>
    </Modal>
  )
};