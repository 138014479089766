import { getDayOfYear } from "date-fns";

export const convertToDate = (timeValue: string, date: Date) => {
  let outputDate = new Date(date);
  const [hours, minutes] = timeValue.split(':').map((n) => parseInt(n, 10));
  outputDate.setHours(hours);
  outputDate.setMinutes(minutes);
  return outputDate;
}

export const tomorrowYesterday = (time: Date) => {
  const currentDay = getDayOfYear(new Date());
  const pickupDay = getDayOfYear(time);
  if (pickupDay - currentDay === 1) {
    return 'tomorrow';
  }
  if (pickupDay - currentDay === 0) {
    return '';
  }
  if (pickupDay - currentDay === -1) {
    return 'yesterday';
  }
  if (pickupDay > currentDay) {
    return `in ${pickupDay - currentDay} days`
  }
  return `${pickupDay - currentDay} days ago`
}