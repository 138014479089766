import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Flex, Text } from "@mantine/core";
import { format } from "date-fns";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";
import { DBUser } from "../../types/DBUser";
import { tomorrowYesterday } from "../../utils/timeUtils";

type RideOptions = {
  id?: string;
  myUserId: string;
  onEdit?: () => void;
};

export const RideRequestedItem = ({
  pickupLocation,
  dropoffLocation,
  pickupTimeRequested,
  pickupTimeRevised,
  requestedForName,
  driverId,
  onEdit,
}: RideRequest & RideOptions) => {
  const [loading, setLoading] = useState(true);
  const [driver, setDriver] = useState<DBUser>();
  useEffect(() => {
    if (driverId) {
      const ref = doc(db.users, driverId);
      getDoc(ref).then((data) => {
        setDriver(data.data());
      }).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [driverId]);
  return (
    <Card withBorder mt="xs" radius="md">
      <Flex justify="space-between">
        <Flex direction="column">
          <Text c="dimmed" fz="sm" mb={-3}>pickup at</Text>
          <Flex align="flex-end">
            <Text fw={700} fz="xl">{format(pickupTimeRevised ?? pickupTimeRequested, 'p')}</Text>
            <Text ml={5} c="dimmed" fz="lg">{tomorrowYesterday(pickupTimeRevised ?? pickupTimeRequested)}</Text>
          </Flex>
          <Text c="dimmed" fz="sm" mb={-3}>pickup location</Text>
          <Text fw={700}>{pickupLocation}</Text>
          <Text c="dimmed" fz="sm" mb={-3} mt={3}>dropoff location</Text>
          <Text fw={700}>{dropoffLocation}</Text>
        </Flex>
        <Button variant="light" disabled={loading} onClick={onEdit}>
          <FontAwesomeIcon icon={faEllipsis} size="2xl" />
        </Button>
      </Flex>
      <Flex mt={5} justify="flex-end">
        <Text fz="sm">
          {
            (!driverId)
              ? 'No Driver assigned yet.'
              : `Your Driver is`
          }
        </Text>
        {
          !!driverId && (
            <Text fz="sm" fw={600} ml={5} color="cyan">
              {driver?.name ?? '...'}
            </Text>
          )
        }
      </Flex>
    </Card>
  );
};