import { faBars, faCar, faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text, AppShell, Divider, Drawer, Flex, Header, NavLink, Badge, ActionIcon } from "@mantine/core"
import { onSnapshot, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { DBUser } from "../../types/DBUser";
import { RideRequest, rideSortFunction } from "../../types/RideRequest";
import { db } from "../../utils/db";
import { EditAccount } from "../riders/EditAccount";
import { ScheduleRide } from "../riders/ScheduleRide";
import { AddDriver } from "./AddDriver";
import { Page, pageMap, pageOrder, RequestedRidesView, RidesMap } from "./RequestedRidesView";

const pageShortDesc = {
  myRides: 'Rides you have claimed',
  unclaimedRides: 'Rides waiting to be claimed',
  otherRides: 'Rides claimed by other Drivers',
} satisfies Record<Page, string>;

export const DriverMenu = ({ user }: { user: DBUser }) => {
  const [page, setPage] = useState<Page>('myRides');
  const [showMenu, setShowMenu] = useState(false);
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [requestingRide, setRequestingRide] = useState(false);
  const myUserId = user.id;
  const [rideRequests, setRideRequests] = useState<RidesMap>();
  const getRideRequests = useCallback(() => {
    const q = query(db.rideRequest, where("rideCompleted", "==", false));
    onSnapshot(q, (refs) => {
      const myRides: RideRequest[] = [];
      const unclaimedRides: RideRequest[] = [];
      const otherRides: RideRequest[] = [];
      refs.forEach(r => {
        const ride = r.data();
        if (ride.driverId === myUserId) {
          myRides.push(ride);
        } else if (!ride.driverId) {
          unclaimedRides.push(ride);
        } else {
          otherRides.push(ride);
        }
      });
      myRides.sort(rideSortFunction);
      unclaimedRides.sort(rideSortFunction);
      otherRides.sort(rideSortFunction);
      setRideRequests({ myRides, unclaimedRides, otherRides });
    });
  }, [myUserId]);
  useEffect(() => getRideRequests(), [getRideRequests]);
  
  const changePage = (newPage: Page) => () => {
    setPage(newPage);
    setShowMenu(false);
    setRequestingRide(false);
  }
  const addDriver = () => {
    setShowAddDriver(true);
    setShowMenu(false);
  }
  const startRideRequest = () => {
    setRequestingRide(true);
    setShowMenu(false);
  }
  return (
    <AppShell
      header={
        <Header height={60} p="sm">
          <Flex justify="space-between" align="center">
            <Flex>
              <ActionIcon
                size="lg"
                onClick={() => setShowMenu(!showMenu)}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={faBars}
                />
              </ActionIcon>
              {
                (rideRequests?.unclaimedRides.length ?? 0) > 0 && page !== 'unclaimedRides' && (
                  <Badge
                    size="md"
                    variant="filled"
                    color="red"
                    w={20}
                    h={20}
                    p={0}
                    ml={-10}
                    style={{ zIndex: 101 }}
                  >
                    {rideRequests?.unclaimedRides.length}
                  </Badge>
                )
              }
            </Flex>
            <Text fz="lg" fw={500}>SCA Rides</Text>
            <EditAccount user={user} />
          </Flex>
        </Header>
      }
    >
      <Drawer
        opened={showMenu}
        onClose={() => setShowMenu(!showMenu)}
        size="xs"
      >
        <Flex direction="column" gap="xs">
          {
            pageOrder.map((newPage) => (
              <NavLink
                label={pageMap[newPage]}
                description={pageShortDesc[newPage]}
                onClick={changePage(newPage)}
                style={{ textAlign: 'left' }}
                active={newPage === page && !requestingRide}
                icon={
                  <Badge
                    size="md"
                    variant="filled"
                    color={
                      newPage === 'unclaimedRides' &&
                      (rideRequests?.[newPage].length ?? 0) > 0 ?
                        'red' : 'gray'
                    }
                    w={20}
                    h={20}
                    p={0}
                  >
                    {rideRequests?.[newPage].length ?? 0}
                  </Badge>
                }
              />
            ))
          }
          <Divider my="sm" />
          <NavLink
            label="Create Ride Request"
            onClick={startRideRequest}
            style={{ textAlign: 'left' }}
            active={requestingRide}
            icon={
              <FontAwesomeIcon
                width={20}
                icon={faCar}
                color="darkGray"
              />
            }
          />
          {
            user.admin && (
              <NavLink
                color="blue"
                label="Add Driver"
                onClick={addDriver}
                style={{ textAlign: 'left' }}
                icon={
                  <FontAwesomeIcon
                    width={20}
                    icon={faPerson}
                    color="darkGray"
                  />
                }
              />
            )
          }
        </Flex>
      </Drawer>
      {
        requestingRide ?
          <ScheduleRide someoneElse user={user} goBack={() => setRequestingRide(false)} /> :
          <RequestedRidesView myUserId={myUserId} page={page} rideRequests={rideRequests} />
      }
      <AddDriver opened={showAddDriver} onClose={() => setShowAddDriver(false)} />
    </AppShell>
  )
}