import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Button, Modal, Text, Flex, ActionIcon } from "@mantine/core"
import { TimeInput } from "@mantine/dates"
import { format } from "date-fns";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";

type Props = {
  rideRequest: RideRequest | undefined;
  onClose: () => void;
}

export const EditPickupTime = ({
  rideRequest,
  onClose,
}: Props) => {
  const ref = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const [newPickupTime, setNewPickupTime] = useState('');
  useEffect(() => {
    if (!rideRequest) return;
    const {
      pickupTimeRevised,
      pickupTimeRequested,
    } = rideRequest;
    setNewPickupTime(
      format(pickupTimeRevised ?? pickupTimeRequested, 'HH:mm'),
    );
  }, [rideRequest]);
  const save = () => {
    if (!rideRequest) return;
    setLoading(true);
    const {
      id,
      pickupTimeRevised,
      pickupTimeRequested,
    } = rideRequest;
    const ref = doc(db.rideRequest, id);
    const newPickupTimeRevised = pickupTimeRevised ?? pickupTimeRequested;
    const [hours, minutes] = newPickupTime.split(':').map((n) => parseInt(n, 10));
    newPickupTimeRevised.setHours(hours);
    newPickupTimeRevised.setMinutes(minutes);
    updateDoc(ref, {
      pickupTimeRevised: newPickupTimeRevised,
    }).then(() => onClose())
      .catch((e) => {
        console.error(e);
        alert(`Something went wrong. ${e}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Modal
      title={<Text>Adjust Pickup Time</Text>}
      opened={!!rideRequest}
      onClose={onClose}
    >
      <TimeInput
        label="New pickup time:"
        // @ts-ignore its just a ref
        ref={ref}
        value={newPickupTime}
        onChange={(e) => setNewPickupTime(e.target.value)}
        disabled={loading || !rideRequest}
        rightSection={
          <ActionIcon onClick={() => ref.current?.showPicker()}>
            <FontAwesomeIcon icon={faClock} />
          </ActionIcon>
        }
      />
      <Flex justify="flex-end" mt="sm">
        <Button onClick={save} disabled={loading || !rideRequest}>
          Save
        </Button>
      </Flex>
    </Modal>
  )
}