import { Flex, Text } from "@mantine/core";
import { useState } from "react";
import { RideRequest } from "../../types/RideRequest";
import { DeleteRide } from "./DeleteRide";
import { EditPickupTime } from "./EditPickupTime";
import { EditRide } from "./EditRide";
import { RideRequestItem } from "./RideRequestItem";

export type RidesMap = {
  myRides: RideRequest[],
  unclaimedRides: RideRequest[],
  otherRides: RideRequest[],
};
export type Page = keyof RidesMap;
export const pageOrder: Page[] = [
  'myRides',
  'unclaimedRides',
  'otherRides',
];
export const pageMap = {
  myRides: 'My Rides',
  unclaimedRides: 'Unclaimed Rides',
  otherRides: 'Other Rides',
} as const satisfies Record<Page, string>;


type Props = {
  myUserId: string;
  rideRequests: RidesMap | undefined;
  page: Page;
};

const RidesSection = ({
  title,
  placeholder,
  rideRequests,
  onEdit,
  myUserId,
}: {
  title: string;
  placeholder: string;
  rideRequests: RideRequest[] | undefined;
  onEdit?: (rideRequest: RideRequest) => void;
  myUserId: string;
}) => (
  <Flex direction="column" mb='md'>
    <Text fz="xl" fw={500}>{title}</Text>
    {
      rideRequests?.map(request => <RideRequestItem key={request.id} {...request} myUserId={myUserId} onEdit={() => onEdit?.(request)} />)
    }
    {
      (rideRequests?.length ?? 0) === 0 && (
        <Text c="dimmed">{placeholder}</Text>
      )
    }
  </Flex>
)


export const RequestedRidesView = ({ myUserId, rideRequests, page }: Props) => {
  const [editingRide, setEditingRide] = useState<RideRequest>();
  const [editingTimeOnRide, setEditingTimeOnRide] = useState<RideRequest>();
  const [deletingRide, setDeletingRide] = useState<RideRequest>();

  return (
    <Flex direction="column" p="lg">
      <EditRide
        editPickupTime={(r: RideRequest | undefined) => setEditingTimeOnRide(r)}
        deleteRide={setDeletingRide}
        rideRequest={editingRide}
        opened={!!editingRide}
        onClose={() => setEditingRide(undefined)}
      />
      <DeleteRide
        rideRequest={deletingRide}
        onClose={() => setDeletingRide(undefined)}
      />
      <EditPickupTime
        rideRequest={editingTimeOnRide}
        onClose={() => setEditingTimeOnRide(undefined)}
      />
      {
        page === 'myRides' && (
          <RidesSection
            title="My rides"
            placeholder="No rides claimed."
            rideRequests={rideRequests?.myRides}
            onEdit={setEditingRide}
            myUserId={myUserId}
          />
        )
      }
      {
        page === 'unclaimedRides' && (
          <RidesSection
            title="Unclaimed rides"
            placeholder="No unclaimed rides."
            rideRequests={rideRequests?.unclaimedRides}
            onEdit={setEditingRide}
            myUserId={myUserId}
          />
        )
      }
      {
        page === 'otherRides' && (
          <RidesSection
            title="Other rides"
            placeholder="No other drivers have any future rides."
            rideRequests={rideRequests?.otherRides}
            onEdit={setEditingRide}
            myUserId={myUserId}
          />
        )
      }
    </Flex>
  )
};