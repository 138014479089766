import { Button, Divider, Drawer, Flex, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { format } from "date-fns";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DBUser } from "../../types/DBUser";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";

type Props = {
  rideRequest: RideRequest | undefined;
  opened: boolean;
  editPickupTime: (rideRequest: RideRequest | undefined) => void;
  deleteRide: (rideRequest: RideRequest | undefined) => void;
  onClose: () => void;
}

export const EditRide = ({
  rideRequest,
  opened,
  editPickupTime,
  deleteRide,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [requestedByUser, setRequestedByUser] = useState<DBUser>();
  const {
    id,
    requestedForName,
    riderPhoneNumber,
    requestedByUserId,
    pickupTimeRevised,
    pickupTimeRequested,
    pickupLocation,
    note,
  } = rideRequest ?? {};
  useEffect(() => {
    if (requestedByUserId) {
      setRequestedByUser(undefined);
      const ref = doc(db.users, requestedByUserId);
      getDoc(ref).then((data) => {
        setRequestedByUser(data.data())
      });
    }
  }, [requestedByUserId]);

  const unclaimRide = () => {
    setLoading(true);
    const ref = doc(db.rideRequest, id);
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to unclaim this ride? This will allow another driver to claim this ride. It will also allow the requestor to delete the ride request.") === true) {
      updateDoc(ref, {
        driverId: null,
        pickupTimeRevised: pickupTimeRevised ?? pickupTimeRequested,
      })
        .then(() => {
          notifications.show({
            title: `Ride for ${requestedForName} unclaimed`,
            message: 'Another driver can now claim this ride.',
            autoClose: true,
          });
        })
        .catch((e) => {
          console.error(e);
          alert(`Something went wrong. ${e}`);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      setLoading(false);
    }
  }
  const completeRide = () => {
    setLoading(true);
    const ref = doc(db.rideRequest, id);
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Please confirm you want to mark this ride as Completed. This cannot be undone.") === true) {
      updateDoc(ref, {
        rideCompleted: true,
      })
        .then(() => {
          notifications.show({
            title: `Ride for ${requestedForName} completed!`,
            message: 'Thanks for driving!',
            autoClose: true,
          });
        })
        .catch((e) => {
          console.error(e);
          alert(`Something went wrong. ${e}`);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      setLoading(false);
    }
  }
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      size="sm"
    >
      <Flex direction="column" gap="xs" mb="xl">
        <Button onClick={() => { editPickupTime(rideRequest); onClose(); }} disabled={loading} mt="lg" variant="light" size="md">Adjust Pickup Time</Button>
        <Button onClick={() => window.open(`tel:${riderPhoneNumber ?? requestedByUser?.phoneNumber}`, '_self')} disabled={loading || !requestedByUser} variant="light" size="md">Call Rider</Button>
        <Button onClick={() => window.open(`sms:${riderPhoneNumber ?? requestedByUser?.phoneNumber}`, '_self')} disabled={loading || !requestedByUser} variant="light" size="md">Text Rider</Button>
        <Divider my="xs" />
        <Button onClick={completeRide} disabled={loading} variant="light" fw={700} size="md">Mark Ride Complete</Button>
        <Button onClick={unclaimRide} disabled={loading} variant="light" size="md" color="grape">Unclaim Ride</Button>
        <Divider my="xs" />
        <Button onClick={() => { deleteRide(rideRequest); onClose(); }} disabled={loading} variant="light" size="md" color="red">Delete Ride</Button>
      </Flex>
      <Text align="right" c="dimmed">Ride for {requestedForName}</Text>
      {
        !!requestedByUserId && (
          <Text align="right" fz="sm" c="dimmed">Requested by {requestedByUser?.name}</Text>
        )
      }
      {
        !!pickupTimeRevised && (
          <Text align="right" c="dimmed" mt="lg">Pickup scheduled for {format(pickupTimeRevised, 'p')}<br />at {pickupLocation}</Text>
        )
      }
      {
        !!note && (
          <Flex direction="column" align="flex-end" mt="lg">
            <Text c="dimmed" fz="sm">Note from Rider:</Text>
            <Text>{note}</Text>
          </Flex>
        )
      }
    </Drawer>
  )
}