import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { PhoneInput } from "../../utils/PhoneInput";
import { TitleHeader } from "./TitleHeader";

const phoneNumberDisclaimer = `
Drivers will use your phone number to contact you.
We will NEVER share or sell your information with third parties.
Upon registering, a text message will be sent to your number for verification.
Standard rates may apply.
`;

type Props = {
  setLoading: (loading: boolean) => void;
  register: (number: string) => void;
  error: string;
}

export const PhoneUtil = ({ register, error }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <Flex p="md" gap="sm" direction="column">
      <TitleHeader />
      <Text
        fz="lg"
        align="center"
        my="md"
      >
        To get started,
        <br />
        please enter your phone number.
      </Text>
      <PhoneInput
        onChangeValue={setPhoneNumber}
        description={phoneNumberDisclaimer}
        error={error}
      />
      <Button
        variant="gradient"
        onClick={() => register(phoneNumber)}
        mt="xs"
        disabled={
          phoneNumber.length < 11
        }
      >
        Submit
      </Button>
    </Flex>
  )
}