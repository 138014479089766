import { useState } from "react";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { TitleHeader } from "./TitleHeader";

type Props = {
  confirm: (code: string) => any;
  loading: boolean;
  goBack: () => void;
  error: string;
}

export const ConfirmationUtil = ({ confirm, loading, goBack, error }: Props) => {
  const [confirmNumber, setConfirmNumber] = useState('');

  return (
    <Flex p="md" gap="sm" direction="column">
      <TitleHeader />
      <Text
        fz="lg"
        align="center"
        my="md"
      >
        We've sent a verification code to the phone
        number you entered.
      </Text>
      <TextInput
        label="Verification Code"
        withAsterisk
        type="number"
        value={confirmNumber}
        onChange={(e) => setConfirmNumber(e.target.value)}
        error={error ? "That didn't work. Please check your verification code and try again." : ''}
      />
      <Button
        variant="gradient"
        onClick={() => confirm(confirmNumber)}
        mt="xs"
        disabled={
          confirmNumber.length < 1 || loading
        }
      >
        Submit
      </Button>
      <Button
        onClick={goBack}
        variant="subtle"
      >
        Go Back
      </Button>
    </Flex>
  )
}