import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Flex, InputBase } from "@mantine/core";
import { useState } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  onChangeValue: (value: string) => void;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  description?: string;
  forceDescription?: boolean;
  error?: string;
}

export const PhoneInput = ({label, forceDescription, defaultValue = '', onChangeValue, disabled, description, error}: Props) => {
  const [showDescription, setShowDescription] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(defaultValue);

  const onChange = (updatedPhoneNumber: string) => {
    setPhoneNumber(updatedPhoneNumber);
    onChangeValue(
      `${updatedPhoneNumber.startsWith('+') ? '' : '+1'}${updatedPhoneNumber}`
    );
  }

  return (
    <InputBase
      label={(
        <Flex>
          {label ?? 'Phone Number'}
          {
            !!description && !forceDescription && (
              <ActionIcon
                ml={5}
                onClick={() => setShowDescription(!showDescription)}
                size="sm"
                color="cyan"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </ActionIcon>
            )
          }
        </Flex>
      )}
      value={phoneNumber}
      disabled={disabled}
      onAccept={onChange}
      component={IMaskInput}
      mask={[
        "0000000000",
        "+0000000000000",
      ]}
      description={(forceDescription || showDescription) && description}
      error={error}
    />
  )
};

// eslint-disable-next-line no-useless-escape
// const stripPhoneNumber = (num: string) => num.replaceAll(/[\(\) -]/g, '');
// const tackOnCountryCode = (num: string) => {
//   if (num.startsWith('+')) {
//     return num;
//   }
//   if (num.length === 10) {
//     return `+1${num}`;
//   }
//   if (num.length > 10) {
//     return `+${num}`;
//   }
//   return num;
// }

// export const PhoneInputNew = ({label, forceDescription, defaultValue = '', onChangeValue, disabled, description, error}: Props) => {
//   const [showDescription, setShowDescription] = useState(false);
//   const [countryCode, setCountryCode] = useState('+1');
//   const [phoneNumber, setPhoneNumber] = useState(defaultValue);

//   const onChange = (updatedPhoneNumber: string) => {
//     setPhoneNumber(updatedPhoneNumber);
//     const cleanNumber = stripPhoneNumber(updatedPhoneNumber);
//     onChangeValue(`${countryCode}${cleanNumber}`);
//   }
//   const changeCountryCode = (newValue: string) => {
    
//   }
//   return (
//     <InputBase
//       label={(
//         <Flex>
//           {label ?? 'Phone Number'}
//           {
//             !!description && !forceDescription && (
//               <ActionIcon
//                 ml={5}
//                 onClick={() => setShowDescription(!showDescription)}
//                 size="sm"
//                 color="cyan"
//               >
//                 <FontAwesomeIcon icon={faQuestionCircle} />
//               </ActionIcon>
//             )
//           }
//         </Flex>
//       )}
//       value={phoneNumber}
//       disabled={disabled}
//       onAccept={onChange}
//       component={IMaskInput}
//       mask="(000) 000-0000"
//       description={(forceDescription || showDescription) && description}
//       error={error}
//     />
//   )
// };
