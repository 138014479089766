import { Button, Flex, Loader, Text } from "@mantine/core";
import { onSnapshot, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { DBUser } from "../../types/DBUser";
import { RideRequest, rideSortFunction } from "../../types/RideRequest";
import { db } from "../../utils/db";
import { EditAccount } from "./EditAccount";
import { EditMyRide } from "./EditMyRide";
import { RideRequestedItem } from "./RideRequestedItem";
import { ScheduleRide } from "./ScheduleRide"

type Props = {
  user: DBUser;
}

export const Rider = ({ user }: Props) => {
  const [rideRequests, setRideRequests] = useState<RideRequest[]>();
  const [editingRide, setEditingRide] = useState<RideRequest>();
  const [schedulingRide, setSchedulingRide] = useState(false);
  const myUserId = user.id;

  const getRideRequests = useCallback(() => {
    const q = query(
      db.rideRequest,
      where("rideCompleted", "==", false),
      where("requestedByUserId", "==", myUserId)
    );
    onSnapshot(q, (refs) => {
      const myRides: RideRequest[] = [];
      refs.forEach(r => {
        const ride = r.data();
        myRides.push(ride);
      });
      myRides.sort(rideSortFunction);
      setRideRequests(myRides);
    });
  }, [myUserId]);
  useEffect(() => getRideRequests(), [getRideRequests]);

  if (schedulingRide) {
    return <ScheduleRide user={user} goBack={() => setSchedulingRide(false)} />;
  }
  return (
    <Flex direction="column" p="lg">
      <EditMyRide
        opened={!!editingRide}
        rideRequest={editingRide}
        onClose={() => setEditingRide(undefined)}
      />
      <Flex justify="space-between" align="center">
        <Text fz="xl" fw={500}>
          Upcoming rides
        </Text>
        <EditAccount user={user} />
      </Flex>
      {
        !!rideRequests && (
          rideRequests.length > 0 ?
            rideRequests.map((r) => (
              <RideRequestedItem
                key={r.id}
                {...r}
                onEdit={() => setEditingRide(r)}
                myUserId={myUserId}
              />
            )) : (
            <Text c="dimmed" mb="md">No upcoming rides.</Text>
          )
        )
      }
      {
        !rideRequests && (
          <Loader size="xl" mt="lg" variant="dots" />
        )
      }
      <Button
        mt="lg"
        onClick={() => setSchedulingRide(true)}
        variant="gradient"
        mb="sm"
        size="lg"
        radius="lg"
      >
        Request a Ride
      </Button>
    </Flex>
  )
}