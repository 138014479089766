import { Button, Divider, Drawer, Flex, Text } from "@mantine/core";
import { format } from "date-fns";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DBUser } from "../../types/DBUser";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";

type Props = {
  rideRequest: RideRequest | undefined;
  opened: boolean;
  onClose: () => void;
}

const cannotChangeCopy = `
If you need to make a change, cancel this ride and request a new one.
`;
const changeRideCopy = `
  Please contact your Driver if you would like to modify this trip.
`;

export const EditMyRide = ({
  rideRequest,
  opened,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState<DBUser>();
  const {
    id,
    driverId,
    requestedForName,
    pickupTimeRevised,
    pickupLocation,
    numRiders,
    note,
  } = rideRequest ?? {};

  useEffect(() => {
    if (driverId) {
      setLoading(true);
      const ref = doc(db.users, driverId);
      getDoc(ref).then((data) => {
        setDriver(data.data());
      }).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [driverId]);

  const deleteRide = async () => {
    if (driverId) {
      alert(changeRideCopy);
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to cancel this ride request?") === true) {
      setLoading(true);
      const ref = doc(db.rideRequest, id);
      await deleteDoc(ref);
      setLoading(false);
      onClose();
    }
  }

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      size="sm"
    >
      <Flex direction="column" gap="xs" mb="xl" pt="lg">
        <Button onClick={() => alert(driverId ? changeRideCopy : cannotChangeCopy)} disabled={loading} variant="light" size="md">Modify Ride Request</Button>
        <Button onClick={() => window.open(`tel:${driver?.phoneNumber}`, '_self')} disabled={loading || !driver} variant="light" size="md">Call Driver</Button>
        <Button onClick={() => window.open(`sms:${driver?.phoneNumber}`, '_self')} disabled={loading || !driver} variant="light" size="md">Text Driver</Button>
        <Divider my="xs" />
        <Button onClick={deleteRide} disabled={loading} variant="outline" size="md" color="red">Cancel Ride Request</Button>
      </Flex>
      {
        !!driverId && (
          <Text mb="md" align="right" c="dimmed">{driver?.name} is your Driver</Text>
        )
      }
      <Text align="right" c="dimmed">This ride is for {requestedForName}</Text>
      {
        (numRiders ?? 0) > 1 && (
          <Text align="right" c="dimmed" fz="sm">with {numRiders} total passengers</Text>
        )
      }
      {
        !!pickupTimeRevised && (
          <Text align="right" c="dimmed" mt="lg">Pickup {!!driverId ? 'scheduled' : 'requested'} for {format(pickupTimeRevised, 'p')}<br />at {pickupLocation}</Text>
        )
      }
      {
        !!note && (
          <Flex direction="column" align="flex-end" mt="lg">
            <Text c="dimmed" fz="sm">Note:</Text>
            <Text>{note}</Text>
          </Flex>
        )
      }
    </Drawer>
  )
}