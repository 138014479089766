import { Button, Flex, LoadingOverlay, TextInput, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { User } from "firebase/auth";
import { doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DBUser } from "../../types/DBUser";
import { auth, db } from "../../utils/db";
import { SignUpUtil } from "./SignUpUtil";
import { TitleHeader } from "./TitleHeader";

type Props = {
  setMyUser: (user: DBUser) => void;
}

export const RegistrationUtil = ({ setMyUser }: Props) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>();
  const [name, setName] = useState('');
  const [err, setError] = useState('');

  const handleSignedIn = () => {
    if (!user) return;
    const ref = doc(db.users, user.uid);
    getDoc(ref).then((data) => {
      if (data.exists()) {
        setMyUser(data.data());
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(handleSignedIn, [user, setMyUser]);

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
    });
  }, []);

  const assignExistingRides = async () => {
    if (!user) return;
    const userId = user.uid
    const q = query(
      db.rideRequest,
      where("riderPhoneNumber", "==", user.phoneNumber)
    );
    const snap = await getDocs(q)
      .catch((err) => {
        notifications.show({
          title: `Something went wrong finding your rides.`,
          message: `${err}`,
          autoClose: false,
        });
        return [];
      });
    const ridesToUpdate: string[] = [];
    snap.forEach((doc) => {
      ridesToUpdate.push(doc.id);
    });
    await
      Promise.all(ridesToUpdate.map(async (id) => {
      const ref = doc(db.rideRequest, id);
      return updateDoc(ref, { requestedByUserId: userId, requestedForName: name });
    })).catch((err) => {
      console.error('uh oh', err);
      notifications.show({
        title: `Something went wrong finding your rides.`,
        message: `${err}`,
        autoClose: false,
      });
    })
  }

  const createAccount = async () => {
    if (!user || !user.phoneNumber) return;
    setLoading(true);
    const ref = doc(db.users, user.uid);
    await setDoc(ref, {
      id: user.uid,
      name,
      role: 'rider',
      phoneNumber: user.phoneNumber,
      admin: false,
    })
      .then(assignExistingRides)
      .then(handleSignedIn)
      .catch(e => setError(`${e}`))
      .finally(() => setLoading(false));
  };

  if (!user) {
    return <SignUpUtil />;
  }

  return (
    <Flex p="md" direction="column" gap="md">
      <LoadingOverlay visible={loading} overlayOpacity={1} />
      <TitleHeader />
      <Text
        fz="lg"
        align="center"
        my="md"
        autoCorrect="none"
      >
        Finally, please enter your name.
      </Text>
      <TextInput
        label="Name"
        withAsterisk
        value={name}
        onChange={e => setName(e.target.value)}
        error={err}
      />
      <Button onClick={createAccount} disabled={!name} variant="gradient">
        Submit
      </Button>
    </Flex>
  )
}