import { faGear, faMoon, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Modal, TextInput, Text, Divider, useMantineColorScheme, Switch } from "@mantine/core";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { DBUser } from "../../types/DBUser";
import { auth, db } from "../../utils/db";

type Props = {
  user: DBUser;
};

export const EditAccount = ({ user }: Props) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(user.name);
  const [showDescription, setShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateAccount = async () => {
    setLoading(true);
    const ref = doc(db.users, user.id);
    await updateDoc(ref, {
      name,
    });
    setLoading(false);
    setOpen(false);
    window.location.reload();
  };

  const handleClose = async () => {
    setName(user.name);
    setOpen(false);
  };

  return (
    <>
      <ActionIcon size="lg">
        <FontAwesomeIcon
          icon={faGear}
          size="xl"
          onClick={() => setOpen(true)}
        />
      </ActionIcon>
      <Modal withCloseButton={false} opened={open} onClose={handleClose}>
        <Flex direction="column" p="lg" gap="md">
          <TextInput
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
          />
          <TextInput
            label="Phone Number"
            description="The phone number associated with your account cannot be changed."
            disabled
            value={user.phoneNumber}
          />
          <Flex align="center">
            Account status: {user.role === 'driver' ? 'Driver' : 'Rider'}
            <ActionIcon
              ml={5}
              onClick={() => setShowDescription(!showDescription)}
              size="sm"
              color="cyan"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </ActionIcon>
          </Flex>
          {
            showDescription && (
              <Text c="dimmed" fz="sm" mt={-10} mb="xs">To update your account status, please reach out to support.</Text>
            )
          }
          <Switch
            label={
              <Flex gap={8} align="center">
                Dark mode
                <FontAwesomeIcon
                  icon={faMoon}
                />
              </Flex>
            }
            checked={colorScheme === 'dark'}
            onChange={() => toggleColorScheme()}
          />
          <Button
            onClick={updateAccount}
            disabled={loading || name === user.name}
          >
            Update
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={async () => {
              await auth.signOut();
              window.location.reload();
            }}
          >
            Log Out
          </Button>
          <Divider />
          <Button
            onClick={handleClose}
            variant="outline"
            disabled={loading}
          >
            Go Back
          </Button>
        </Flex>
      </Modal>
    </>
  )
};