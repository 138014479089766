import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Flex, Text } from "@mantine/core";
import { format } from "date-fns";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";
import { tomorrowYesterday } from "../../utils/timeUtils";
import { DBUser } from "../../types/DBUser";
import { notifications } from "@mantine/notifications";

type RideOptions = {
  id?: string;
  myUserId: string;
  onEdit?: () => void;
};

export const RideRequestItem = ({
  id,
  pickupLocation,
  dropoffLocation,
  pickupTimeRequested,
  pickupTimeRevised,
  requestedForName,
  numRiders,
  driverId,
  myUserId,
  onEdit,
}: RideRequest & RideOptions) => {
  const [loading, setLoading] = useState(false);
  const myRide = myUserId === driverId;
  const [driver, setDriver] = useState<DBUser>();
  useEffect(() => {
    if (driverId) {
      setDriver(undefined);
      const ref = doc(db.users, driverId);
      getDoc(ref).then((data) => {
        setDriver(data.data())
      });
    }
  }, [driverId]);

  const claimRide = () => {
    if (driverId) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(`This ride is currently claimed by ${driver?.name ?? 'N/A'}. Are you sure you want to take over ownership?`)) {
        return;
      }
    }
    setLoading(true);
    const ref = doc(db.rideRequest, id);
    updateDoc(ref, {
      driverId: myUserId,
      pickupTimeRevised: pickupTimeRevised ?? pickupTimeRequested,
    })
      .then(() => {
        notifications.show({
          title: `Ride for ${requestedForName} claimed!`,
          message: 'This ride has been moved into your "My Rides" tab.',
          autoClose: true,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Card withBorder mt="xs">
      <Flex justify="space-between">
        <div>
          <Text c="dimmed" fz="sm" mb={-5}>Ride for</Text>
          <Text fz="lg" fw={700}>{requestedForName}</Text>
        </div>
        {
          myRide ? (
            <Button variant="light" disabled={loading} onClick={onEdit}>
              <FontAwesomeIcon icon={faEllipsis} size="2xl" />
            </Button>
          ) : (
            <Button variant="gradient" onClick={claimRide} disabled={loading}>
              Claim
            </Button>
          )
        }
      </Flex>
      {
        numRiders > 0 && (
          <Text c="dimmed" mt={-3} mb={5}>{numRiders} total passengers</Text>
        )
      }
      <Flex>
        <Text c="dimmed">pickup at</Text>
        <Flex>
          <Text mx={5} fw={700}>{format(pickupTimeRevised ?? pickupTimeRequested, 'p')}</Text>
          <Text>{tomorrowYesterday(pickupTimeRevised ?? pickupTimeRequested)}</Text>
        </Flex>
      </Flex>
      <Flex wrap="wrap">
        <Text c="dimmed" style={{ whiteSpace: 'nowrap' }}>from</Text>
        <Text mx={5}>{pickupLocation}</Text>
      </Flex>
      <Flex wrap="wrap">
        <Text c="dimmed" style={{ whiteSpace: 'nowrap' }}>dropoff at</Text>
        <Text mx={5}>{dropoffLocation}</Text>
      </Flex>
      {
        !!driverId && driverId !== myUserId && (
          <Flex mt="xs" justify="flex-end">
            <Text c="dimmed" fs="sm">ride claimed by</Text>
            <Text c="cyan" fs="sm" fw={500} ml={5}>{driver?.name ?? '...'}</Text>
          </Flex>
        )
      }
    </Card>
  );
};