import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { auth, setupRecaptcha } from "../../utils/db";
import { PhoneUtil } from "./PhoneSignUp";
import { ConfirmationUtil } from "./ConfirmationUtil";
import { ConfirmationResult, signInWithPhoneNumber } from "firebase/auth";

const captchaPlaceholder = 'captchaPlaceholder';

export const SignUpUtil = () => {
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState(false);
  const [error, setError] = useState('');

  const [confirmationResult, setConfirmationResult] = useState<
    ConfirmationResult | null
  >(null);

  useEffect(() => {
    auth.onAuthStateChanged(currentUser => {
      if (!currentUser) {
        setLoading(false);
      }
    });
  }, []);

  const signUp = async (phoneNumber: string) => {
    setProcess(true);
    setError('');
    setLoading(true);
    const recaptchaVerifier = setupRecaptcha(captchaPlaceholder);
    const newConfirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .catch((err) => {
        setError(`${err}`);
        console.error(err);
        return null;
      });
    setConfirmationResult(newConfirmationResult);
    setLoading(false);
  }

  const confirm = async (confirmationNumber: string) => {
    setError('');
    setLoading(true)
    await confirmationResult?.confirm(confirmationNumber)
      .catch((err) => {
        setError(`${err}`);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <div id={captchaPlaceholder} />
      <LoadingOverlay
        visible={loading}
        overlayOpacity={process ? undefined : 1}
      />
      {
        !!confirmationResult ? (
          <ConfirmationUtil
            error={error}
            loading={loading}
            confirm={confirm}
            goBack={() => {
              window.location.reload();
            }}
          />
        ) : (
          <PhoneUtil
            setLoading={setLoading}
            register={signUp}
            error={error}
          />
        )
      }
    </div>
  )
}