import { useState } from "react";
import { Rider as RiderPage } from "./riders/Rider";
import { RegistrationUtil } from "./signup/RegistrationUtil";
import { DBUser } from "../types/DBUser";
import { DriverMenu } from "./drivers/DriverMenu";

export const MainPage = () => {
  const [me, setMe] = useState<DBUser | null>(null);
  // const [event, setEvent] = useState<SCAEvent>();
  // const eventId = '57BLLP7rpXPYgIYbqSlz';
  // useEffect(() => {
  //   const ref = doc(db.events, eventId);
  //   getDoc(ref).then((data) => {
  //     setEvent(data.data())
  //   })
  // }, [eventId]);

  if (!me) {
    return <RegistrationUtil setMyUser={setMe} />;
  }
  if (me.role === 'driver') {
    return <DriverMenu user={me} />;
  }
  return <RiderPage user={me} />;
}