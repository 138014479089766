import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getFirestore, QueryDocumentSnapshot, collection, DocumentData, Timestamp } from "firebase/firestore"
import { DBUser } from "../types/DBUser";
import { RideRequest } from "../types/RideRequest";
import { SCAEvent } from "../types/SCAEvent";

const firebaseConfig = {
  apiKey: "AIzaSyDjBX6QyVNk5IL-gu2nTO7BZcadNnE7XeM",
  authDomain: "sca-rides.firebaseapp.com",
  projectId: "sca-rides",
  storageBucket: "sca-rides.appspot.com",
  messagingSenderId: "12726825718",
  appId: "1:12726825718:web:84378e6c850baa83a472d7",
  measurementId: "G-73X01L2JLN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(app);

export const auth = getAuth(app);
auth.useDeviceLanguage();

export const setupRecaptcha = (element: HTMLElement | string) => {
  return new RecaptchaVerifier(element, {
    size: 'invisible',
    callback: (response: any) => {
    },
  }, auth);
}
  
const converter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) =>
    Object.entries(snap.data())
      .reduce((out, [k, v]) => {
        if (v instanceof Timestamp) {
          out[k] = v.toDate();
        } else {
          out[k] = v;
        }
        return out;
      }, { id: snap.id } as Record<string, any>) as T
})

const dataPoint = <T extends DocumentData>(collectionPath: string) =>
  collection(firestoreDB, collectionPath)
    .withConverter(converter<T>())

export const db = {
  users: dataPoint<DBUser>('users'),
  events: dataPoint<SCAEvent>('events'),
  rideRequest: dataPoint<RideRequest>('ride-requests'),
}
