import { Button, Modal, Text, Flex, Checkbox } from "@mantine/core"
import { notifications } from "@mantine/notifications";
import { deleteDoc, doc } from "firebase/firestore";
import { useState } from "react";
import { RideRequest } from "../../types/RideRequest";
import { db } from "../../utils/db";

type Props = {
  rideRequest: RideRequest | undefined;
  onClose: () => void;
}

export const DeleteRide = ({
  rideRequest,
  onClose,
}: Props) => {
  const [iUnderstand, setIUnderstand] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteRide = () => {
    if (!rideRequest) return;
    setLoading(true);
    const ref = doc(db.rideRequest, rideRequest.id);
    deleteDoc(ref)
      .then(() => {
        notifications.show({
          title: `Ride for ${rideRequest.requestedForName} deleted`,
          message: 'Make sure the rider knows.',
          autoClose: true,
        });
        setIUnderstand(false);
        onClose();
      })
      .catch((e) => {
        console.error(e);
        alert(`Something went wrong. ${e}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Modal
      title={<Text fw={600} fz="lg">Delete Ride</Text>}
      opened={!!rideRequest}
      onClose={onClose}
    >
      <Flex mt="sm" direction="column" gap="md">
        <Text>
          Are you SURE you want to delete this Ride?
          <br />
          <br />
          This action cannot be undone. The rider will NOT be notified
          of the cancellation of their ride. Before deleting this ride,
          please make sure to communicate with the rider.
        </Text>
        <Checkbox
          label="I understand"
          checked={iUnderstand}
          onChange={() => setIUnderstand(!iUnderstand)}
          disabled={loading}
        />
        <Button onClick={deleteRide} disabled={loading || !rideRequest || !iUnderstand} color="red">
          Delete Ride
        </Button>
        <Button onClick={onClose} disabled={loading} variant="outline">
          Go Back
        </Button>
      </Flex>
    </Modal>
  )
}