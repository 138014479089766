export type RideRequest = {
  id?: string;
  requestedByUserId: string;
  requestedForName: string;
  riderPhoneNumber: string | null;
  pickupLocation: string;
  dropoffLocation: string;
  note: string;
  pickupTimeRequested: Date;
  pickupTimeRevised: Date | null;
  numRiders: number;
  driverId: string | null;
  rideCompleted: boolean;
}

export const rideSortFunction =
  (a: RideRequest, b: RideRequest) =>
    ((a.pickupTimeRevised ?? a.pickupTimeRequested).getTime()
    - (b.pickupTimeRevised ?? b.pickupTimeRequested).getTime());
