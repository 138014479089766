import { Text } from '@mantine/core';

export const TitleHeader = () => (
  <>
    <Text
      fz="xl"
      align="center"
      fw={700}
    >
      Welcome to SCA Rides
    </Text>
    <Text
      align="center"
      mt={-16}
      c="dimmed"
      fs="sm"
    >
      v1.0 beta
    </Text>
  </>
)