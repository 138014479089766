import { Button, Checkbox, Flex, Modal } from "@mantine/core";
import { useState } from "react";

type Props = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const eventCustomText = `
No armor on the carts!
`;

export const EventModal = ({ open, onConfirm, onClose }: Props) => {
  const [agreed, setAgreed] = useState(false);
  return(
    <Modal
      title={eventCustomText}
      opened={open}
      onClose={onClose}
      centered
    >
      <Flex
        direction="column"
        gap="xs"
      >
        <Checkbox
          label="I understand and agree"
          checked={agreed}
          onChange={() => setAgreed(!agreed)}
          mt="xs"
          mb="lg"
        />
        <Button
          variant="gradient"
          onClick={onConfirm}
          disabled={!agreed}
        >
          Submit Ride Request
        </Button>
      </Flex>
    </Modal>
  )
};